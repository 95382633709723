import React from 'react';
import { Link } from 'gatsby';

import * as Footer from '../ui-components/footer';
import * as Grid from '../ui-components/grid';

const FooterSection = () => (
  <Footer.FooterWrapper>
    <Footer.FooterContact>
      <Grid.Container>
        <Grid.Row>
          <Footer.FooterContactColumn>
            <Footer.FooterContactList>
              <Footer.FooterContactListItem>
                <Footer.FooterContactListItemTitle>
                  Contact options:
                </Footer.FooterContactListItemTitle>
              </Footer.FooterContactListItem>
              <Footer.FooterContactListItem>
                T:{' '}
                <Footer.FooterContactTel>
                  +972-54-8412410
                </Footer.FooterContactTel>
              </Footer.FooterContactListItem>
              <Footer.FooterContactListItem>
                E:{' '}
                <a href="mailto:info@nostack.net">
                  <span>info@nostack.net</span>
                </a>
              </Footer.FooterContactListItem>
            </Footer.FooterContactList>
          </Footer.FooterContactColumn>

          <Footer.FooterLogoColumn>
            <Footer.FooterLogo to="/">
              <svg width="106.4" height="82.3">
                <use xlinkHref="/sprites.svg#logo" />
              </svg>
            </Footer.FooterLogo>
          </Footer.FooterLogoColumn>
        </Grid.Row>
      </Grid.Container>
    </Footer.FooterContact>

    <Footer.FooterBottom>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col6>
            <Footer.FooterBottomSignature>
              Built with <a href="https://www.gatsbyjs.org/">Gatsby</a> &&nbsp;
              <Link to="/">no-stack</Link>
            </Footer.FooterBottomSignature>
          </Grid.Col6>

          <Grid.Col6>
            <Footer.FooterBottomList>
              <Footer.FooterBottomListItem>
                <Link to="/">Terms and Conditions</Link>
              </Footer.FooterBottomListItem>
              <Footer.FooterBottomListItem>
                <Footer.FooterCopyright>
                  &copy; {new Date().getFullYear()} All rights reserved
                </Footer.FooterCopyright>
              </Footer.FooterBottomListItem>
            </Footer.FooterBottomList>
          </Grid.Col6>
        </Grid.Row>
      </Grid.Container>
    </Footer.FooterBottom>
  </Footer.FooterWrapper>
);

export default FooterSection;
