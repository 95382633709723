import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import { Container, Row, Col6 } from './grid';
import variables from './variables';

const FooterBottomMixin = css`
  color: ${variables.colors.lightGray};
  font-size: 20px;
  line-height: 20px;
  white-space: nowrap;
`;

const FooterBottomMixin460 = css`
  @media only screen and (max-width: 460px) {
    font-size: 18px;
    line-height: 35px;
  }
`;

export const FooterCopyright = styled.p`
  color: ${variables.colors.lightGray};
`;

export const FooterBottomListItem = styled.li`
  a {
    ${FooterBottomMixin}

    ${FooterBottomMixin460}
  }

  display: inline-block;

  @media only screen and (max-width: 518px) {
    display: block;
  }
`;

export const FooterBottomList = styled.ul`
  ${FooterBottomMixin}

  display: flex;
  justify-content: flex-end;

  > ${FooterBottomListItem}:last-child {
    border-left: 1px solid ${variables.colors.lightGray};
    margin-left: 15px;
    padding-left: 15px;
  }

  @media only screen and (max-width: 900px) {
    display: block;
    padding-bottom: 20px;
  }

  @media only screen and (max-width: 518px) {
    > ${FooterBottomListItem}:last-child {
      margin-left: 0;
      padding-left: 0;
      border: 0;
      margin-top: 20px;
    }
  }

  ${FooterBottomMixin460}

  @media only screen and (max-width: 460px) {
    > ${FooterBottomListItem}:last-child {
      margin: 1px 0 14px;
    }
  }
`;

export const FooterBottomSignature = styled.p`
  a {
    color: ${variables.colors.white};
  }

  ${FooterBottomMixin}

  ${FooterBottomMixin460}
`;

export const FooterBottom = styled.div`
  padding: 30px 0;
  background: ${variables.colors.black};

  a:hover {
    text-decoration: none;
  }

  @media only screen and (max-width: 900px) {
    ${Row} {
      flex-direction: column-reverse;
      text-align: center;
    }

    ${Col6} {
      width: 100%;
    }
  }

  @media only screen and (max-width: 460px) {
    padding: 33px 0;
  }
`;

export const FooterLogo = styled(Link)`
  background: ${variables.colors.white};
  display: block;
  box-shadow: none;
`;

export const FooterLogoColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  width: 188px;
  background: ${variables.colors.white};

  @media only screen and (max-width: 460px) {
    width: 100%;
    padding: 31px 0;
  }
`;

export const FooterContactTel = styled.span``;

export const FooterContactListItemTitle = styled.h3`
  color: ${variables.colors.white};
  font-family: ${variables.fontFamilies.openSans};
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  margin-right: 10px;

  @media only screen and (max-width: 460px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const FooterContactListItem = styled.li`
  display: inline-block;
  font-size: 24px;
  line-height: 24px;

  a {
    color: ${variables.colors.white};
    text-decoration: none;
    box-shadow: none;

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    color: ${variables.colors.white};
  }

  @media only screen and (max-width: 900px) {
    display: block;
    padding-bottom: 20px;
  }

  @media only screen and (max-width: 460px) {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    padding-bottom: 15px;
  }
`;

export const FooterContactList = styled.ul`
  > ${FooterContactListItem}:last-child {
    border-left: 1px solid ${variables.colors.white};
    margin-left: 15px;
    padding-left: 15px;

    @media only screen and (max-width: 900px) {
      margin-left: 0;
      padding-left: 0;
      border: 0;
    }
  }

  @media only screen and (max-width: 460px) {
    padding: 49px 20px 13px;
  }
`;

export const FooterContactColumn = styled.div`
  width: 100%;
  position: relative;
`;

export const FooterContact = styled.div`
  ${Container} {
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: -43px;
      left: 0;

      width: 58px;
      height: 58px;
      background: ${variables.colors.white};

      border-radius: 3px;

      transform: rotate(45deg);
    }
  }

  ${Row} {
    flex-direction: row;
    flex-wrap: nowrap;
    height: 247px;
    align-items: center;
  }

  @media only screen and (max-width: 1560px) {
    ${Container}::before {
      left: 50%;
      margin-left: -29px;
    }
  }

  @media only screen and (max-width: 460px) {
    ${Container} {
      padding: 0;
    }

    ${Row} {
      display: block;
      height: auto;
    }
  }
`;

export const FooterWrapper = styled.footer`
  background: ${variables.colors.green};
`;
