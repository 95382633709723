import React from 'react';

import HeaderSection from './HeaderSection';
import FooterSection from './FooterSection';

const Layout = ({ children }) => (
  <>
    <HeaderSection />
    {children}
    <FooterSection />
  </>
);

export default Layout;
