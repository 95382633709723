import styled from 'styled-components';

export const Col8 = styled.div`
  width: calc(100% - (100% / 3));
`;

export const Col6 = styled.div`
  width: 50%;
`;

export const Col4 = styled.div`
  width: calc(100% / 3);
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

export const GappedRow = styled(Row)`
  margin: 0 -15px;

  div {
    padding: 0 15px;
  }
`;

export const MainSection = styled.main``;

export const Container = styled.div`
  width: 1100px;
  margin: 0 auto;

  @media only screen and (max-width: 1140px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
`;
