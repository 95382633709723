import React from 'react';
import { withPrefix } from 'gatsby';

import * as Grid from '../ui-components/grid';
import * as Header from '../ui-components/header';
// import * as Nav from '../ui-components/navigation';

const HeaderSection = () => (
  <Header.Wrapper>
    <Grid.Container>
      <Grid.Row>
        <Grid.Col6>
          <Header.Logo to="/">
            <svg width="165.7" height="128.13">
              <use xlinkHref={withPrefix('sprites.svg#logo')} />
            </svg>
          </Header.Logo>
        </Grid.Col6>

        {/* <Grid.Col6> */}
        {/*   <Nav.Nav> */}
        {/*     <Nav.BurgerButton type="checkbox" name="btnBurger" id="btnBurger" /> */}
        {/*     <Nav.BurgerSlice /> */}
        {/*     <Nav.BurgerSlice /> */}
        {/*     <Nav.BurgerSlice /> */}

        {/*     <Nav.NavList> */}
        {/*       <Nav.NavListItem> */}
        {/*         <Nav.NavLink to="/">About</Nav.NavLink> */}
        {/*       </Nav.NavListItem> */}
        {/*       <Nav.NavListItem> */}
        {/*         <Nav.NavLink to="/">Contact</Nav.NavLink> */}
        {/*       </Nav.NavListItem> */}
        {/*     </Nav.NavList> */}
        {/*   </Nav.Nav> */}
        {/* </Grid.Col6> */}
      </Grid.Row>
    </Grid.Container>
  </Header.Wrapper>
);

export default HeaderSection;
