import styled from 'styled-components';
import { Link } from 'gatsby';

export const Logo = styled(Link)`
  box-shadow: none;

  @media only screen and (max-width: 460px) {
    display: block;
    width: 66px;
    height: 86px;

    svg {
      width: 66px;
      height: 86px;
    }
  }
`;

export const Wrapper = styled.header`
  padding: 44px 0 0;
  width: 100%;

  @media only screen and (max-width: 460px) {
    padding: 0;
  }
`;
